import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import BannerImage from '../../../../../gatsby-theme-husky/src/components/Banner/BannerImage';
import {
  BannerMode,
  BannerProps,
} from '../../../../../gatsby-theme-husky/src/components/Banner/models.d';
import useScreenRecognition from '../../../../../gatsby-theme-husky/src/hooks/useScreenRecognition';
import { DangerouslySetInnerHtml } from '../../../../../gatsby-theme-husky/src/layout';
import { BANNER_BACKGROUND_COLOR } from '../../../../../gatsby-theme-husky/src/utils/constants';

import './Banner.scss';

const Banner: FC<BannerProps> = ({
  description,
  title,
  alt,
  mode = BannerMode.article,
  background = BANNER_BACKGROUND_COLOR.pink,
  image,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <Row data-testid={`${mode}-banner`} className={`banner banner--${background}`}>
      {isMobile && mode === BannerMode.product ? (
        <div data-testid={`${mode}-mobile-banner`}>
          <div className={`banner__left-section banner__left-section--${mode}`}>
            <Col className="banner__information">
              <DangerouslySetInnerHtml
                element="h1"
                className={`banner__title banner__title--${mode}`}
                html={title}
              />
            </Col>
          </div>
          <Col>
            <DangerouslySetInnerHtml
              html={description}
              className={`banner__description banner__description--${mode}`}
              data-testid="banner-description"
            />
            <div className={`banner__right-section banner__right-section--${mode}`}>
              <div className={`banner__image banner__image--${mode}`}>
                <BannerImage image={image} alt={alt} />
              </div>
            </div>
          </Col>
        </div>
      ) : (
        <>
          <div className={`banner__left-section banner__left-section--${mode}`}>
            <Col className="banner__information">
              <DangerouslySetInnerHtml
                element="h1"
                className={`banner__title banner__title--${mode}`}
                html={title}
              />
              <DangerouslySetInnerHtml
                html={description}
                className={`banner__description banner__description--${mode}`}
                data-testid="banner-description"
              />
            </Col>
          </div>
          <div className={`banner__right-section banner__right-section--${mode}`}>
            <div className={`banner__image banner__image--${mode}`}>
              <BannerImage image={image} alt={alt} />
            </div>
          </div>
        </>
      )}
    </Row>
  );
};

export default Banner;
